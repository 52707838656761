<template>
    <div>
        <div class="table-responsive">
            <table class="table table-bordered table-hover">
                <thead>
                <tr>
                    <th>Item</th>
                    <th>Amount</th>
                    <th>GST%</th>
                    <th>GST Amount</th>
                    <th>Total</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="(detail.fnb !== null)">
                    <td>F&B</td>
                    <td id="amount">
                        {{$global.numberToLocationString(totalAmountS(detail.fnb).amount)}}
                    </td>
                    <td>{{ $global.numberToLocationString(totalAmountS(detail.fnb).gst)}}</td>
                    <td id="amount">
                        {{$global.numberToLocationString(totalAmountS(detail.fnb).gstAmount)}}
                    </td>
                    <td id="amount">
                        {{$global.numberToLocationString(totalAmountS(detail.fnb).total)}}
                    </td>
                </tr>
                <tr v-else>
                    <td>F&B</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr v-if="(detail.fnbExtras !== null)">
                    <td>Fnb Extra</td>
                    <td id="amount">
                        {{$global.numberToLocationString(totalAmountS({'fnbExtras':detail.fnbExtras,'fnbExtrasDiscount':detail.fnbExtrasDiscount}).amount)}}
                    </td>
                    <td>
                        {{$global.numberToLocationString(totalAmountS({'fnbExtras':detail.fnbExtras,'fnbExtrasDiscount':detail.fnbExtrasDiscount}).gst)}}
                    </td>
                    <td id="amount">
                        {{$global.numberToLocationString(totalAmountS({'fnbExtras':detail.fnbExtras,'fnbExtrasDiscount':detail.fnbExtrasDiscount}).gstAmount)}}
                    </td>
                    <td id="amount">
                        {{$global.numberToLocationString(totalAmountS({'fnbExtras':detail.fnbExtras,'fnbExtrasDiscount':detail.fnbExtrasDiscount}).total)}}
                    </td>
                </tr>
                <tr v-if="(detail.decors !== null)">
                    <td>Decors</td>
                    <td id="amount">
                        {{$global.numberToLocationString(totalAmountS({'decors':detail.decors,'decorsDiscount':detail.decorsDiscount}).amount)}}
                    </td>
                    <td>
                        {{$global.numberToLocationString(totalAmountS({'decors':detail.decors,'decorsDiscount':detail.decorsDiscount}).gst)}}
                    </td>
                    <td id="amount">
                        {{$global.numberToLocationString(totalAmountS({'decors':detail.decors,'decorsDiscount':detail.decorsDiscount}).gstAmount)}}
                    </td>
                    <td id="amount">
                        {{$global.numberToLocationString(totalAmountS({'decors':detail.decors,'decorsDiscount':detail.decorsDiscount}).total)}}
                    </td>
                </tr>
                <tr v-if="(detail.otherCharges !== null)">
                    <td>Other Charges</td>
                    <td id="amount">
                        {{$global.numberToLocationString(totalAmountS({'otherCharges':detail.otherCharges,'otherChargesDiscount':detail.otherChargesDiscount}).amount)}}
                    </td>
                    <td>-</td>
                    <td id="amount">
                        {{$global.numberToLocationString(totalAmountS({'otherCharges':detail.otherCharges,'otherChargesDiscount':detail.otherChargesDiscount}).gstAmount)}}
                    </td>
                    <td id="amount">
                        {{$global.numberToLocationString(totalAmountS({'otherCharges':detail.otherCharges,'otherChargesDiscount':detail.otherChargesDiscount}).total)}}
                    </td>
                </tr>
                <tr>
                    <td>Total</td>
                    <td id="amount">
                        <strong>
                            {{$global.numberToLocationString(totalAmountS(detail.fnb).amount +
                            (totalAmountS({'fnbExtras':detail.fnbExtras,'fnbExtrasDiscount':detail.fnbExtrasDiscount}).amount)
                            +(totalAmountS({'decors':detail.decors,'decorsDiscount':detail.decorsDiscount}).amount)
                            +(totalAmountS({'otherCharges':detail.otherCharges,'otherChargesDiscount':detail.otherChargesDiscount}).amount))}}
                        </strong>
                    </td>
                    <td></td>
                    <td id="amount">
                        <strong>
                            {{$global.numberToLocationString(totalAmountS(detail.fnb).gstAmount +
                            (totalAmountS({'fnbExtras':detail.fnbExtras,'fnbExtrasDiscount':detail.fnbExtrasDiscount}).gstAmount)
                            +(totalAmountS({'decors':detail.decors,'decorsDiscount':detail.decorsDiscount}).gstAmount)
                            +(totalAmountS({'otherCharges':detail.otherCharges,'otherChargesDiscount':detail.otherChargesDiscount}).gstAmount))}}
                        </strong>
                    </td>
                    <td id="amount">
                        <strong>
                            {{$global.numberToLocationString(totalAmountS(detail.fnb).total +
                            (totalAmountS({'fnbExtras':detail.fnbExtras,'fnbExtrasDiscount':detail.fnbExtrasDiscount}).total)
                            +(totalAmountS({'decors':detail.decors,'decorsDiscount':detail.decorsDiscount}).total)
                            +(totalAmountS({'otherCharges':detail.otherCharges,'otherChargesDiscount':detail.otherChargesDiscount}).total))}}
                        </strong>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import Error from "@/core/services/Error";
    import {request} from "@/core/services/Request";
    import totalAmountMixin from "../../../../../assets/js/components/totalAmountMixin";

    export default {
        mixins: [totalAmountMixin],
        props: ["event_id"],
        data() {
            return {
                detail: [],
                dropdowns: {
                    halls: [],
                }
            }
        },
        mounted() {
            this.handleEditOperation(this.event_id)
        },
        methods: {
            async handleEditOperation(id) {
                try {
                    const response = await request({
                        method: 'get',
                        url: `/quotations/events/detail/${id}`,
                    })
                    const {data} = response.data
                    this.detail = data
                } catch (e) {
                    this.$global.itemEditFails()
                }
            },
            totalAmount(data) {
                let amount = 0;
                let gst = 0;
                let total = 0;
                let gstAmount = 0;
                if (data) {
                    if (Array.isArray(data)) {
                        _.map(data, (i) => {
                            if (i.rate !== undefined) {
                                amount += parseFloat((i.no_of_pax != undefined) ? (i.rate * i.no_of_pax) : i.rate);
                            }
                            if (i.gst) {
                                gst += parseFloat(i.gst);
                            }
                            gstAmount += parseFloat((amount * gst) / 100)
                            total += (parseFloat(gstAmount + amount));
                        })
                    } else {
                        if ((data.rate !== undefined && data.rate)) {
                            amount += parseFloat(data.no_of_pax ? (data.rate * data.no_of_pax) : data.rate)
                        }
                        if (data.hall_rent) {
                            amount += parseFloat(data.hall_rent)
                        }
                        gst += (data.hall_gst !== undefined) ? data.hall_gst : data.gst

                        gstAmount += parseFloat(((gst * amount) / 100))
                        total += parseFloat((gstAmount + amount))
                    }
                }
                return {'amount': amount, 'gst': gst, 'total': total, 'gstAmount': gstAmount}
            },
        },
        computed: {
            ...mapState([
                'global',
            ]),
        },
    }
</script>
