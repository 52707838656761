import Error from "@/core/services/Error";
import {request} from "@/core/services/Request";
import {bus} from "@/main";
import {scroller} from 'vue-scrollto/src/scrollTo';

const DEFAULT_FORM_STATE = {
  lead_id: null,
  event_id: null,
  customer_billing_address: null,
  gst_no: null,
  pan_no: null,
  discount_type: 'percentage',
  alternative_phone: null,
  discount_amount: 0,
  gst: 0,
};

const COLUMN_DEFINATION = () => [
  {
    text: "#",
    sortable: false,
    value: 'id',
  },
  {
    text: "Lead",
    sortable: false,
    value: 'leads',
  },
  {
    text: "Dates",
    sortable: true,
    value: "start_date",
    width: "8%",
  },
  {
    text: "Sub Total",
    sortable: false,
    value: 'sub_total',
  },
  {
    text: "GST",
    sortable: false,
    value: 'gst_amount',
    width: "5%",
  },
  {
    text: "Total",
    sortable: false,
    value: 'total',
  },
  {
    text: "Paid Amount",
    sortable: false,
    value: 'paid',
  },
  {
    text: "Due Amount",
    sortable: false,
    value: 'due_amount',
  },
  {
    text: "Due Date",
    sortable: false,
    value: 'due_date',
    width: "8%",
  },
  {
    text: "Created at",
    sortable: true,
    value: 'created_at',
    width: "8%",
  },
  {
    text: "Action",
    value: "action",
    sortable: false,
    width: "15%",
    align: "center"
  },
];

export default {
  data() {
    return {
      operationTitle: "Bookings",
      lead: {},
      event: {},
      status: null,
      discount_amount: 0,
      formFields: {...DEFAULT_FORM_STATE},
      formErrors: new Error({}),
      dropdowns: {
        leads: [],
        events: [],
        halls: [],
        sources: [],
        eventType: [],
        subUsers: [],
        discountType: [
          {value: 'percentage', text: 'Percentage',},
          {value: 'fixed', text: 'Fixed'}],

        rooms: [],
        status: [
          {id: '2', title: 'Mark as Postponed'},
          {id: '3', title: 'Mark as Cancelled'}
        ]
      },
      listUrl: `/bookings?type=${this.booking_type}`,
      columns: COLUMN_DEFINATION(this),
      EventId: null,
      quotationEventDetail: [],
      pdf: null,
    };
  },
  computed: {
    total_amount() {
      return this.totalEventAmount(this.formFields.quotationEvents).amount + this.totalRoomAmount(this.formFields.quotationRooms).amount
    },
  },
  methods: {
    scrollToElement(refName) {
      const scrollTo = scroller();
      scrollTo(refName);
    },

    handleDiscount() {

      if (this.formFields.discount_type && this.formFields.discount_amount) {

        if (this.formFields.discount_type === 'percentage') {
          this.discount_amount = this.total_amount * this.formFields.discount_amount / 100;
        } else this.discount_amount = this.formFields.discount_amount;

      } else this.discount_amount = 0;

    },
    async refreshData() {
      await this.handleEditOperation(this.$route.params.id);
    },
    async getRooms() {
      try {
        const response = await request({
          url: `/dropdowns/rooms`,
          method: 'post',
        });

        const {data} = response.data;
        this.dropdowns.rooms = data;
      } catch (e) {

      }
    },
    async report(type) {

      try {
        const response = await request({
          url: `/bookings/reports/${type}`,
          params: this.filters,
          method: 'get',
        });

        const {data} = response;

        window.open(data.url, '_blank');
      } catch (e) {
        console.error(e)
      }
    },
    dateDisabled(ymd, date) {
      var myCurrentDate = new Date(date);
      var myPastDate = new Date(myCurrentDate);
      myPastDate.setDate(myPastDate.getDate() + 1);

      return (myPastDate < new Date(this.filters.from_date));
    },
    async handleCancel() {
      if (this.checkPendingEventData) {
        let deleteResponse = await this.$root.$confirm("Event Changes are not saved", "Previous changes are not saved, do you want to save the changes?", {
          primaryBtnText: 'Yes',
          secondaryBtnText: 'No'
        });

        if (deleteResponse) {
          this.$store.dispatch("checkPendingEventData", 0);
          this.scrollToElement("#quotationEvents");
        } else {
          this.$store.dispatch("checkPendingEventData", 0);
          this.$router.push('/bookings');
          this.afterCloseOperation();
        }
      } else if (this.checkPendingRoomData) {
        let deleteResponse = await this.$root.$confirm("Room Changes are not saved", "Previous changes are not saved, do you want to save the changes?", {
          primaryBtnText: 'Yes',
          secondaryBtnText: 'No'
        });

        if (deleteResponse) {
          this.$store.dispatch("checkPendingRoomData", 0);
          this.scrollToElement("#quotationRooms");
        } else {
          this.$store.dispatch("checkPendingRoomData", 0);
          this.$router.push('/bookings');
          this.afterCloseOperation();
        }
      } else if (this.checkFinancialPendingData) {
        let deleteResponse = await this.$root.$confirm("Financial Changes are not saved", "Previous changes are not saved, do you want to save the changes?", {
          primaryBtnText: 'Yes',
          secondaryBtnText: 'No'
        });

        if (deleteResponse) {
          this.$store.dispatch("checkFinancialPendingData", 0);
          this.scrollToElement("#quotationFinancial");
        } else {
          this.$store.dispatch("checkFinancialPendingData", 0);
          this.$router.push('/bookings');
          this.afterCloseOperation();
        }
      } else {
        this.$store.dispatch("checkPendingEventData", 0);
        this.$router.push('/bookings');
        this.afterCloseOperation();
      }
    },
    async handleSubmit() {

      try {
        const response = await request({
          url: this.formFields.id ? '/bookings/update' : '/bookings/create',
          method: 'post',
          data: this.formFields,
        });

        const {data} = response.data;
        if (this.formFields.id) {
          this.$global.itemUpdated();
        } else {
          this.$global.itemAdded();
        }

      } catch (error) {
        if (error.request && error.request.status && error.request.status === 422) {
          this.errors = error.data.errors;
          return false;
        }
        this.$global.handleServerError(error);
      }
    },
    handleQuotationEventDetail(detail) {
      this.quotationEventDetail = detail;
    },
    // handleOperationCloseCheck() {
    //     if (this.handler) {
    //         if (confirm('changes that you made may not be saved?')) {
    //             this.handleQuotationCustomerDetailSubmit()
    //         }
    //         return false;
    //     } else {
    //         this.handleOperationClose()
    //     }
    // },
    afterCloseOperation() {
      this.formFields = {...DEFAULT_FORM_STATE};
    },
    handleChangeOperations(operation = 'add', operationDataId = null) {
      this.afterCloseOperation();
      this.$router.replace({
        query: Object.assign({},
          this.$route.query,
          {
            ...this.listQueryParams,
            operation: operation,
            id: operationDataId,
          },
        ),
      });
    },
    async sendToWhatsapp(id) {
      try {
        const response = await request({
          method: 'get',
          url: `bookings/send/pdf/${id}/booking`,
        });

        this.$global.sendQuotation();

      } catch (e) {
        console.error(e);
      }
    },
    async handleQuotationCustomerDetailSubmitAndCancel() {
      this.errors = [];
      try {
        const response = await request({
          url: this.formFields.id ? '/quotations/update' : '/quotations/create',
          method: 'post',
          data: this.formFields,
        });

        this.formFields.lead_id = this.lead.id;
        this.formFields.event_id = this.event.id;

        const {data} = response.data;
        if (this.formFields.id) {
          this.$global.itemUpdated();
        } else {
          this.$global.itemAdded();
        }
        this.handleOperationClose();
      } catch (error) {
        if (error.request && error.request.status && error.request.status === 422) {
          this.errors = error.data.errors;
          return false;
        }
        this.$global.handleServerError(error);
      }
    },
    async handleQuotationCustomerDetailSubmit() {
      this.errors = [];
      try {

        this.formFields.event_id = this.event.id;
        this.formFields.lead_id = this.lead.id;

        const response = await request({
          url: this.formFields.id ? '/quotations/update' : '/quotations/create',
          method: 'post',
          data: this.formFields,
        });
        const {data} = response.data;
        if (this.formFields.id) {
          this.$global.itemUpdated();
        } else {
          this.$global.itemAdded();
        }
        this.handler = null;

        this.handleEditOperation(this.$route.query.id);
      } catch (error) {
        if (error.request && error.request.status && error.request.status === 422) {
          this.errors = error.data.errors;
          return false;
        }
        this.$global.handleServerError(error);
      }
    },
    async getLeads() {
      try {
        const response = await request({
          url: `/dropdowns/leads`,
          method: 'post',
        });

        const {data} = response.data;
        this.dropdowns.leads = data.map((item) => {
          return {
            value: item.id,
            label: item.first_name + item.last_name + " (" + item.mobile_number + ")"
          };
        });
      } catch (e) {

      }
    },
    async handleEventChange() {
      if (this.lead.id) {
        this.dropdowns.events = await this.getEvents(this.lead.id);
      } else {
        this.dropdowns.events = [];
      }
    },
    async getEvents(lead_id) {
      try {
        const response = await request({
          url: `/dropdowns/quotation/events`,
          method: 'post',
          data: {lead_id: lead_id, booking: true}
        });

        const {data} = response.data;
        return data;
      } catch (e) {

      }
    },
    async handleEditOperation(id) {
      try {
        const response = await request({
          method: 'get',
          url: `/bookings/detail/${id}`,
        });
        const {data} = response.data;
        this.formFields = {
          ...data,
          event_id: data.events ? data.events.id : null,
          lead_id: data.leads ? data.leads.id : null,
        };

        this.lead = data.leads;
        await this.handleEventChange(this.lead.id);

        this.event = data.events;

        this.formFields.discount_amount = data.discount_amount;
        this.formFields.discount_type = data.discount_type;
        this.oldData = _.cloneDeep(this.formFields);

        this.status = _.filter(this.dropdowns.status, (i) => i.id == data.status);

        this.handleDiscount()
      } catch (e) {
        this.$global.itemEditFails();
        this.formFields = {...DEFAULT_FORM_STATE};
      }
    },
    async handleDeleteOperation(id) {
      let deleteResponse = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
        primaryBtnText: 'Yes',
        secondaryBtnText: 'No'
      });
      if (deleteResponse) {
        try {
          const response = await request({
            method: 'post',
            url: '/bookings/delete',
            data: {
              id: id,
            },
          });
          this.$global.itemDeleted();
          this.loadList(this.listQueryParams);
        } catch (errors) {
          this.$global.handleServerError(errors);
        }
      }
    },
    selectedEventId(id) {
      this.EventId = id;
    },
    async handleSubmitOperation(id, status) {
      this.errors = [];

      try {
        const response = await request({
          url: '/bookings/update',
          method: 'post',
          data: {
            id: id,
            status: status
          },
        });

        this.loadList(this.listQueryParams);
      } catch (error) {
        if (error.request && error.request.status && error.request.status === 422) {
          this.errors = error.data.errors;
          return false;
        }
        this.$global.handleServerError(error);
      }
    },
    handleChangeInput() {
      this.addHandler(this.formFields);
    },
    showPdf() {
      this.downloadPdf(this.formFields.id);
    },
    async downloadPdf(id, type = null, lang = 'en') {

      if (type && type !== 'summary') {
        await this.downloaCustomerdPdf(id, type, 'download', lang);
      } else {
        try {
          const response = await request({
            method: 'get',
            url: `bookings/download/pdf/${id}` + (type ? `/${type}` : ''),
          });

          const {data} = response.data;

          if (data) {
            window.open(data.download_url, '_blank');
          }

        } catch (e) {
        }
      }
    },
    async downloaCustomerdPdf(id, type, action = 'download', lang = 'en') {
      try {
        const response = await request({
          method: 'get',
          params: { lang },
          url: `bookings/download/customer/pdf/${id}/${type}/${action}`,
        });
        const {data} = response.data;
        if (data) {
          if (action === "download") {
            window.open(data.download_url, '_blank');
          }
        }

      } catch (e) {
      }
    },
    async sendConfirmation(id) {
      try {
        const response = await request({
          method: 'get',
          url: `bookings/${id}/send_confirmation_message`,
        });
        const {data} = response.data;
        if (data) {
          this.$global.notify("Message sent");
        }

      } catch (e) {
      }
    },
    async handleBookingOperations(id) {
      this.errors = [];
      try {
        const response = await request({
          url: '/quotations/to/booking',
          method: 'post',
          data: {
            id: id,
          },
        });
        const {data} = response.data;
        if (_.isArray(data)) {
          this.bookData = data;
          this.$bvModal.show("book-form");
        } else {
          this.$global.itemUpdated();
        }
        this.loadList(this.listQueryParams);
      } catch (error) {
        if (error.request && error.request.status && error.request.status === 422) {
          this.errors = error.data.errors;
          return false;
        }
        this.$global.handleServerError(error);
      }
    },
    handleOperationClose() {
      this.formFields = {...DEFAULT_FORM_STATE};
      this.$router.push({name: "bookingList"});
    },

    async getHalls() {
      try {
        const response = await request({
          url: `/dropdowns/halls`,
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.halls = data.map((item) => {
          return {
            id: item.id,
            label: item.name,
          };
        });
      } catch (e) {

      }
    },
    async getSources() {
      try {
        const response = await request({
          url: `/dropdowns/sources`,
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.sources = data.map((item) => {
          return {
            id: item.id,
            label: item.source_name,
          };
        });
      } catch (e) {

      }
    },
    async getEventType() {
      try {
        const response = await request({
          url: '/dropdowns/event/type',
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.eventType = data.map((item) => {
          return {
            id: item,
            label: item,
          };
        });
      } catch (e) {

      }
    },
    async getSubUser() {
      try {
        const response = await request({
          url: `/dropdowns/sub/user`,
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.subUsers = data.map((item) => {
          return {
            id: item.id,
            label: item.name,
          };
        });
      } catch (e) {

      }
    },
  },
};
